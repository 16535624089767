import React, { FunctionComponent } from 'react';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { datadogRum } from '@datadog/browser-rum';
import { useMicroFrontend } from '@havenengineering/module-shared-micro-frontend-consumer/react';
import { Button } from '@havenengineering/module-shared-owners-ui/dist/components/Button';
import { Content } from '@havenengineering/module-shared-owners-ui/dist/components/Content';
import { Footer } from '@havenengineering/module-shared-owners-ui/dist/components/Footer';
import { Header } from '@havenengineering/module-shared-owners-ui/dist/components/Header';
import { useAuthContext } from '@havenengineering/module-shared-owners-ui/dist/contexts/auth';
import clsx from 'clsx';

import { AccountSelector } from '../components/AccountSelector/AccountSelector';
import { fetchWrapper, withAuthenticationUrl } from '../helpers/fetch';
import { useLettingAdverts } from '../hooks/useLettingAdverts';
import styles from './MainLayout.module.scss';

const {
  publicRuntimeConfig: { PUBLIC_LOGIN_URL, PUBLIC_ENV },
} = getConfig();

export const yourHolidayHomeTooltipRef = React.createRef<HTMLElement>();

export const MainLayout: FunctionComponent = ({ children }) => {
  const { loggedInUser, activeAccount, activeAccountId } = useAuthContext();
  const router = useRouter();
  const microFrontend = useMicroFrontend('navigation');

  const logoutHandler = async () => {
    try {
      await fetchWrapper(withAuthenticationUrl('/logout'), {
        method: 'POST',
        body: '{}',
        credentials: 'include',
      });
      window.location.assign(window.location.origin);
      return;
    } catch (error: any) {
      window.alert('Failed to logout, please try again');
    }
  };

  const loginHandler = () => {
    window.location.assign(
      `${PUBLIC_LOGIN_URL}?redirect=${window.location.href}`
    );
  };

  const handleLogoClick = () => router.push('/');

  const { actionRequired: lettingActionRequired } = useLettingAdverts(
    activeAccount?.accountID,
    {
      getActionRequired: true,
    }
  );

  const dataDogUser = datadogRum.getUser();
  if (!dataDogUser?.id && activeAccountId) {
    datadogRum.setUser({
      id: activeAccountId?.toString(),
    });
  }

  return (
    <>
      <Header
        userLoggedIn={!!loggedInUser}
        handleLogin={loginHandler}
        handleLogout={logoutHandler}
        handleLogoClick={handleLogoClick}
        environment={PUBLIC_ENV}
        accountNo={activeAccount?.accountNo}
        accountId={activeAccount?.accountID}
      />

      {lettingActionRequired && (
        <div className={styles.actionRequiredContainer}>
          <img src="/assets/notification-white.svg" alt="" /> ACTION REQUIRED{' '}
          <Button
            size="small"
            variant="outlinedWhite"
            className={styles.calendarButton}
            onClick={() => router.push('/bookings?from=waitlistAd')}
          >
            Go to calendar
          </Button>
        </div>
      )}

      <microFrontend.navigation
        activeAccountNumber={activeAccount?.accountNo}
        ownerAccountNumbers={
          loggedInUser?.accounts?.map((a) => a.accountNo) || []
        }
        application="DASHBOARD"
        activePath={router.pathname}
        onNavigate={(path: string) => router.push(path)}
        yourHolidayHomeTooltipRef={yourHolidayHomeTooltipRef}
      />

      <Content isScrollable>
        <div
          className={clsx(styles.lgContainer, {
            ['has-van-selector']:
              loggedInUser && loggedInUser.accounts.length > 1,
          })}
        >
          <AccountSelector />
          {children}
        </div>
      </Content>
      <Footer />
    </>
  );
};
