import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Button } from '@havenengineering/module-shared-owners-ui/dist/components/Button';
import { EventData } from '@havenengineering/module-shared-owners-ui/dist/components/EventCalendar';
import { isPast } from '@havenengineering/module-shared-owners-ui/dist/components/EventCalendar/utils';
import { useAuthContext } from '@havenengineering/module-shared-owners-ui/dist/contexts/auth';
import clsx from 'clsx';
import { DateTime } from 'luxon';

import { LettingsContext } from '../../contexts/lettings';
import { isOffer } from '../../helpers/calendar';
import { ArrivalDataEnum, ParkClosedDataTypeEnum } from '../../pages/bookings';
import { BreakVisitType } from '../../types/lettings';
import { LabelBadge } from '../EventCalendarApendix/LabelBadge';
import {
  fetchSwappableBreaks,
  getBreakLabel,
  SwappableBreaksResponse,
} from '../helpers/bookings';
import styles from './EventListItem.module.scss';

type EventListItemProps = {
  event: EventData;
  handleEdit?: (eventSelection: EventData) => void;
  handleCancel?: (eventSelection: EventData) => void;
  handleGetCarPass?: (EventSelection: EventData) => void;
  loading?: boolean;
  handleSetError?: (error: string) => void;
  handleSetLoading?: (loading: boolean) => void;
  breakVisitType: BreakVisitType;
  isRemovalDisabled: boolean;
  allPeakDatesData: PeakDatesData[];
};

export const EventListItem: FunctionComponent<EventListItemProps> = ({
  event,
  handleEdit,
  handleCancel,
  handleGetCarPass,
  loading,
  breakVisitType,
  isRemovalDisabled,
  allPeakDatesData,
}) => {
  const { lettingSummary } = useContext(LettingsContext);

  const isArrivalBooking = event.code === ArrivalDataEnum.ARRIVAL_BOOKING;

  const startDate = isArrivalBooking
    ? event.data.dateArrival
    : event.data.startDate;

  const endDate = isArrivalBooking
    ? event.data.dueToLeaveDate
    : event.data.endDate;

  const showDeleteIcon = useMemo((): boolean => {
    if ([BreakVisitType.LET_WITH_HAVEN].includes(breakVisitType)) {
      return !event?.data?.status?.withinSixWeeks;
    }

    return (
      isArrivalBooking ||
      (!isArrivalBooking && !isPast(DateTime.fromISO(startDate)))
    );
  }, [
    breakVisitType,
    event?.data?.status?.withinSixWeeks,
    isArrivalBooking,
    startDate,
  ]);

  const isParkClosedEvent = event.code === ParkClosedDataTypeEnum.PARK_CLOSED;

  const { activeAccount } = useAuthContext();
  const [swappableBreaks, setSwappableBreaks] =
    useState<SwappableBreaksResponse>({});

  const getSwappableBreaks = useCallback(async () => {
    const validLet2OwnLetWithHavenBookingToEdit =
      lettingSummary?.let2Own &&
      breakVisitType === BreakVisitType.LET_WITH_HAVEN &&
      !isArrivalBooking &&
      !event?.data?.status?.withinSixWeeks;

    if (!activeAccount?.accountID || !validLet2OwnLetWithHavenBookingToEdit) {
      return;
    }

    try {
      const response = await fetchSwappableBreaks(
        activeAccount?.accountID,
        event.data.startDate
      );

      setSwappableBreaks(response || {});
    } catch (error) {
      console.error(error);
      setSwappableBreaks({});
    }
  }, [
    activeAccount?.accountID,
    breakVisitType,
    event.data.startDate,
    event.data?.status?.withinSixWeeks,
    isArrivalBooking,
    lettingSummary?.let2Own,
  ]);

  useEffect(() => {
    getSwappableBreaks();
  }, [getSwappableBreaks]);

  const showEditButton = useMemo(() => {
    const validArrivalToEdit =
      isArrivalBooking && !(event.data as ArrivalBooking).isCancelled;

    const validLet2OwnLetWithHavenBookingToEdit =
      lettingSummary?.let2Own &&
      breakVisitType === BreakVisitType.LET_WITH_HAVEN &&
      !isArrivalBooking &&
      !event?.data?.status?.withinSixWeeks &&
      Object.keys(swappableBreaks).length > 0;

    return (
      handleEdit &&
      (validArrivalToEdit || validLet2OwnLetWithHavenBookingToEdit)
    );
  }, [
    breakVisitType,
    event.data,
    handleEdit,
    isArrivalBooking,
    lettingSummary?.let2Own,
    swappableBreaks,
  ]);

  if (!event.data) {
    return (
      <div className={styles.dataLessWrapper}>
        <LabelBadge label={event.label || ''} />
      </div>
    );
  }

  return (
    <>
      <div className={styles.item} key={event.id}>
        <div className={styles.details}>
          <div className={clsx(styles.label, 'fs-mask')}>
            <LabelBadge
              value={event.value}
              code={event.code}
              label={event.label || ''}
            />
          </div>
          <div className={styles.dates}>{`${DateTime.fromISO(
            startDate
          ).toFormat('ccc dd LLL')} - ${DateTime.fromISO(endDate).toFormat(
            'ccc dd LLL yyyy'
          )}`}</div>
          <div className={styles.numOfNights}>
            {getBreakLabel(
              startDate,
              endDate,
              breakVisitType,
              allPeakDatesData,
              lettingSummary?.let2Own
            )}
          </div>
        </div>
        <div className={styles.actions}>
          {isArrivalBooking &&
            !(event.data as ArrivalBooking).isCancelled &&
            handleGetCarPass && (
              <Button
                variant="outlined"
                size="small"
                onClick={() => handleGetCarPass(event)}
                disabled={loading}
                isLoading={loading}
              >
                Download car pass
              </Button>
            )}
          {showEditButton && (
            <img
              className={styles.editIcon}
              src="/assets/icon-edit.svg"
              alt="edit-icon-list"
              onClick={() => handleEdit!(event)}
              role="presentation"
            />
          )}
          {!event.data?.cancelled &&
            !isOffer(event.code) &&
            !isParkClosedEvent &&
            handleCancel &&
            showDeleteIcon && (
              <img
                className={clsx(
                  styles.deleteIcon,
                  isRemovalDisabled ? styles.disabled : ''
                )}
                src="/assets/icon-delete.svg"
                alt="delete-icon"
                onClick={() => !isRemovalDisabled && handleCancel(event)}
                role="presentation"
              />
            )}
        </div>
      </div>
    </>
  );
};
