import { useMemo, useState } from 'react';
import { ConfirmationOverlay } from '@havenengineering/module-shared-owners-ui/dist/components/ConfirmationOverlay/ConfirmationOverlay';
import { Let2offsetApplicationCard } from '@havenengineering/module-shared-owners-ui/dist/components/Let2offsetApplicationCard/Let2offsetApplicationCard';

import { fetchWrapper, withApiBaseUrl } from '../../helpers/fetch';
import { parseLettingConfigItem } from '../../helpers/lettingMarketing';
import { LettingConfigEntry } from '../../types/lettingConfig';
import { LettingAccount } from '../../types/lettings';
import {
  Let2OwnWarningModalContent,
  OptOutReason,
} from './Let2OwnWarningModalContent/Let2OwnWarningModalContent';

export interface AccountUpdate {
  accountNumber: string;
  isRenewing: boolean;
  readyForRenewal?: boolean;
  optOutReason?: OptOutReason;
}

interface Let2offsetApplicationProps {
  activeAccount: UserAccount;
  lettingAccount: LettingAccount;
  lettingConfig: LettingConfigEntry[];
}

export const Let2offsetApplication = ({
  activeAccount,
  lettingAccount,
  lettingConfig,
}: Let2offsetApplicationProps) => {
  const [openLet2OwnWarningModal, setOpenLet2OwnWarningModal] =
    useState<boolean>(false);

  const isRenewalPeriodActive = useMemo(
    () => parseLettingConfigItem(lettingConfig || [], 'isRenewalPeriodActive'),
    [lettingConfig]
  );

  const showLet2OwnBanner = useMemo(
    () =>
      (lettingAccount?.isRenewing || lettingAccount?.readyForRenewal) &&
      isRenewalPeriodActive === true,
    [
      isRenewalPeriodActive,
      lettingAccount?.isRenewing,
      lettingAccount?.readyForRenewal,
    ]
  );

  if (!showLet2OwnBanner) return null;

  const handleLetToOwnRenewal = async (
    accountUpdate: AccountUpdate,
    redirectUrl: string
  ) => {
    try {
      await fetchWrapper(withApiBaseUrl(`/letting/account`), {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify(accountUpdate),
      });
    } catch (error) {
      console.error(error);
    }

    window.location.assign(redirectUrl);
  };

  return (
    <>
      <ConfirmationOverlay
        type="alert"
        isOpen={openLet2OwnWarningModal}
        content={
          <Let2OwnWarningModalContent
            activeAccount={activeAccount}
            handleLetToOwnRenewal={handleLetToOwnRenewal}
          />
        }
        handleCancel={() => {
          setOpenLet2OwnWarningModal(false);
        }}
      />
      <Let2offsetApplicationCard
        handleContinueWithRenewal={async () =>
          window.location.assign('/bookings')
        }
        handleLetToOwnRenewal={() =>
          handleLetToOwnRenewal(
            {
              accountNumber: activeAccount.accountNo as string,
              isRenewing: true,
            },
            '/bookings'
          )
        }
        isRenewing={lettingAccount.isRenewing}
        setOpenLet2OwnWarningModal={setOpenLet2OwnWarningModal}
      />
    </>
  );
};
