import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { Button } from '@havenengineering/module-shared-owners-ui/dist/components/Button';
import clsx from 'clsx';

import {
  addParamsToContentfulImage,
  transformImageParams,
} from '../../helpers/contentful-image-api';
import { fetchWrapper, withApiBaseUrl } from '../../helpers/fetch';
import {
  handleLettingAdvertDismiss,
  handleLettingAdvertGAView,
  handleLettingAdvertSubmit,
} from '../../helpers/googleTag';
import styles from './FullScreenManagedLettingAdvert.module.scss';

export interface FullScreenManagedLettingAdvert {
  id: string;
  campaignName: string;
  campaignText: string;
  campaignMedia: string;
  callToAction: string;
  callToActionLink: string;
  isDisplayable: boolean;
  maxVanAge: number;
  daysTillShown: number;
  vanGrades?: string;
}

interface Props {
  advertData: FullScreenManagedLettingAdvert;
  handleClose: () => void;
}

export const FullScreenManagedLettingAdvert: FunctionComponent<Props> = ({
  advertData,
  handleClose,
}) => {
  const updateAdvertTrack = useCallback(
    (action: string) => {
      fetchWrapper(withApiBaseUrl('/advertTracks/upsert'), {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          action,
          advertId: advertData.id,
          name: 'fullScreenLettingAdvert',
        }),
      });
    },
    [advertData.id]
  );

  useEffect(() => {
    handleLettingAdvertGAView();
    updateAdvertTrack('No action');
  }, [updateAdvertTrack]);

  const onCTAclick = () => {
    handleLettingAdvertSubmit(advertData.callToActionLink);
    updateAdvertTrack('CTA button clicked');
    window.location.assign(advertData.callToActionLink);
  };

  const onClose = () => {
    handleLettingAdvertDismiss();
    updateAdvertTrack('Close button clicked');
    handleClose();
  };

  return (
    <div className={styles.AdRoot}>
      <div className={styles.Ad}>
        {advertData.campaignMedia && (
          <div className={styles.AdImageWrapper}>
            <img
              src={addParamsToContentfulImage(
                advertData.campaignMedia,
                transformImageParams({
                  width: 500,
                  height: 500,
                })
              )}
            />
          </div>
        )}

        <div className={clsx(styles.AdContent, 'owners-body')}>
          <h1 className="owners-heading-1">{advertData.campaignName}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: advertData.campaignText,
            }}
          />
          <Button onClick={onCTAclick}>{advertData.callToAction}</Button>
          <Button
            variant="text"
            className={styles.AdClose}
            onClick={onClose}
            data-testid="close-ad"
          >
            <img
              src="https://www.haven.com/assets/icons/close.svg"
              width={16}
              height={16}
              alt="close"
            />
          </Button>
        </div>
      </div>
    </div>
  );
};
