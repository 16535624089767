import React, { FunctionComponent } from 'react';
import { Button } from '@havenengineering/module-shared-owners-ui/dist/components/Button';
import clsx from 'clsx';

import {
  addParamsToContentfulImage,
  transformImageParams,
} from '../../helpers/contentful-image-api';
import styles from './HomepageBanner.module.scss';

interface HomepageBannerProps {
  banner: HomepageBannerData;
  handleCtaClick: () => void;
}

const HomepageBanner: FunctionComponent<HomepageBannerProps> = ({
  banner: {
    ctaText,
    labelText,
    heading,
    subHeading,
    backgroundColor,
    heroImage,
  },
  handleCtaClick,
}) => {
  return (
    <div className={clsx(styles.root, styles[backgroundColor])}>
      <img
        src={addParamsToContentfulImage(
          heroImage,
          transformImageParams({ quality: 95 })
        )}
        alt="Homepage banner"
        loading="eager"
      />
      <div className={clsx(styles.curve, styles[backgroundColor])} />
      <div
        className={styles.label}
        dangerouslySetInnerHTML={{ __html: labelText }}
      />
      <div className={styles.body}>
        <div className={styles.text}>
          <h1>{heading}</h1>
          {subHeading && <h4>{subHeading}</h4>}
        </div>
        <Button
          variant="outlinedWhite"
          className={styles.link}
          onClick={() => handleCtaClick()}
        >
          {ctaText}
        </Button>
      </div>
    </div>
  );
};

export default HomepageBanner;
