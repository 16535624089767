import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAuthContext } from '@havenengineering/module-shared-owners-ui/dist/contexts/auth';

import { fetchWrapper, withApiBaseUrl } from '../helpers/fetch';
import { LettingSummary } from '../types/lettings';

export type LettingsContextState = {
  lettingSummary: LettingSummary | null;
  isLet2OwnRenewal: boolean;
  isLoading: boolean;
  let2OwnRenewalCompleted: boolean;
  refetchLettingSummary: () => Promise<void>;
  partialPaymentMade: boolean;
};

const LettingsContext = createContext<LettingsContextState>({
  lettingSummary: null,
  isLet2OwnRenewal: false,
  isLoading: true,
  let2OwnRenewalCompleted: false,
  refetchLettingSummary: async () => {},
  partialPaymentMade: false,
});

const LettingsContextProvider: React.FunctionComponent = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { activeAccount } = useAuthContext();
  const [lettingSummary, setLettingSummary] = useState<LettingSummary | null>(
    null
  );

  const fetchLettingSummary = useCallback(async () => {
    if (!activeAccount?.accountID) {
      return;
    }

    try {
      const values = await fetchWrapper(
        withApiBaseUrl(`/letting/letting-summary/${activeAccount.accountID}`),
        {
          method: 'GET',
          credentials: 'include',
        }
      );
      setLettingSummary(values);
    } catch (error) {
      setLettingSummary(null);
    } finally {
      setIsLoading(false);
    }
  }, [activeAccount]);

  useEffect(() => {
    fetchLettingSummary();
  }, [activeAccount, fetchLettingSummary]);

  const context = useMemo(
    () => ({
      lettingSummary,
      isLet2OwnRenewal:
        (lettingSummary?.let2Own && lettingSummary?.isRenewing) || false,
      isLoading,
      partialPaymentMade: lettingSummary?.partialPaymentMade || false,
      let2OwnRenewalCompleted:
        (lettingSummary?.let2Own && lettingSummary?.renewalCompleted) || false,
      refetchLettingSummary: () => fetchLettingSummary(),
    }),
    [lettingSummary, isLoading, fetchLettingSummary]
  );

  return (
    <LettingsContext.Provider value={context}>
      {children}
    </LettingsContext.Provider>
  );
};

export { LettingsContext, LettingsContextProvider };
