import { RefObject, useEffect, useState } from 'react';

type Relation = 'above' | 'inside' | 'below';

export const useViewportRelation = (
  ref: RefObject<HTMLDivElement>,
  { threshold = 0, rootMargin = '0px', enable = true } = {}
) => {
  const [relation, setRelation] = useState<Relation | null>(null);

  // everything is inside useEffect, because WebAPI isn't available on
  // the server side, and useEffect only runs in the client. That's okay,
  // because visibility is only applicable on the client side.
  useEffect(() => {
    if (ref.current == null || !enable) {
      return () => {};
    }
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setRelation('inside');
          return;
        }
        setRelation(entry.boundingClientRect.top > 0 ? 'below' : 'above');
      },
      {
        threshold,
        rootMargin,
      }
    );
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => observer.disconnect();
  }, [ref, enable, rootMargin, threshold]);

  return relation;
};
