import React, { FunctionComponent } from 'react';
import Link from 'next/link';
import { Badge } from '@havenengineering/module-shared-owners-ui/dist/components/Badge';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import useSWR from 'swr';

import {
  addParamsToContentfulImage,
  transformImageParams,
} from '../../../helpers/contentful-image-api';
import { swrFetcher } from '../../../helpers/fetch';
import styles from './SingleRead.module.scss';

interface Props {
  article?: Article;
  showHeroImage?: boolean;
  bottomBorder?: boolean;
  detailView?: boolean;
}

const SingleRead: FunctionComponent<Props> = ({
  article,
  showHeroImage,
  bottomBorder,
  detailView,
}) => {
  const { data } = useSWR<{ articlesRead: string[] }>(
    '/contentful/articles-read',
    swrFetcher
  );

  return (
    <>
      {article && (
        <div
          className={clsx(
            styles.articleContainer,
            bottomBorder && styles.bottomBorder
          )}
          data-testid={'important#' + article.id}
        >
          <Link
            href="/news-and-information/[id]"
            as={`/news-and-information/${article.id}`}
            legacyBehavior
          >
            <a className={styles.link}>
              <div>
                {showHeroImage && (
                  <div className={styles.imageContainer}>
                    {article.heroImage ? (
                      article.heroImage.includes('mp4') ? (
                        <video height={250} controls>
                          <source
                            src={`https:${article.heroImage}`}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <img
                          src={addParamsToContentfulImage(
                            article.heroImage,
                            transformImageParams({ quality: 95, height: 250 })
                          )}
                          alt={article.title}
                        />
                      )
                    ) : (
                      <div className={styles.placeholder}>
                        <img src="/assets/megaphone.svg" alt="" />
                      </div>
                    )}
                  </div>
                )}
                <div className={styles.titleContainer}>
                  <div
                    className={
                      detailView
                        ? clsx('owners-heading-1', styles.detailViewTitle)
                        : styles.title
                    }
                  >
                    {article.title}
                    {!detailView && (
                      <img src="/assets/icon-navchevron-right.svg" />
                    )}
                  </div>
                </div>
                <div>
                  <div className={styles.details}>
                    <strong>
                      {article.date
                        ? DateTime.fromISO(article.date).toFormat('dd LLL yy')
                        : 'Unknown time'}
                    </strong>
                    <span>
                      {article.author === 'Hemel' ? 'Central' : article.author}{' '}
                      Team
                    </span>
                    {data?.articlesRead?.includes(article.id) && (
                      <Badge
                        text={'READ'}
                        icon={<img src="/assets/check-white.svg" />}
                      />
                    )}
                  </div>
                </div>
              </div>
            </a>
          </Link>
        </div>
      )}
    </>
  );
};

export default SingleRead;
