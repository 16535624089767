import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { Button } from '@havenengineering/module-shared-owners-ui/dist/components/Button';
import { useAuthContext } from '@havenengineering/module-shared-owners-ui/dist/contexts/auth';

import { handleGTMBannerImpression } from '../../helpers/googleTag';
import { useOnScreen } from '../../hooks/useOnScreen';
import styles from './OwnersGuideBanner.module.scss';

const ownersGuideBannerReadKey = 'ownersGuideBannerRead';

import getConfig from 'next/config';

const {
  publicRuntimeConfig: { PUBLIC_APP_URL },
} = getConfig();

const didSaleCompleteInHalfYear = (saleCompletionDate: string | undefined) => {
  if (!saleCompletionDate) {
    return false;
  }

  const date = new Date();
  date.setMonth(date.getMonth() - 6);
  return new Date(saleCompletionDate) > date;
};

const OwnersGuideBanner = () => {
  const router = useRouter();
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);
  const [isViewed, setIsViewed] = useState(false);

  const { activeAccount } = useAuthContext();

  const [ownersGuideBannerClosed, setOwnersGuideBannerClosed] = useState(true);

  useEffect(() => {
    // Load whether user has already closed the owners guide banner or not.
    setOwnersGuideBannerClosed(
      !!localStorage.getItem(ownersGuideBannerReadKey)
    );
  }, []);

  useEffect(() => {
    if (isVisible) setIsViewed(true);
  }, [isVisible]);

  const title = `${activeAccount?.parkName || 'Holiday park'} owners guide`;
  const gtmOptions = useMemo(
    () => ({
      button_cta: 'go to guides',
      image_url: `${PUBLIC_APP_URL}/assets/welcome-guides.svg`,
      link_url: `/welcome-guides`,
      title: title.toLowerCase(),
      page_position: 'top',
      variant: 'owners park guide',
    }),
    [title]
  );

  useEffect(() => {
    if (isViewed) {
      handleGTMBannerImpression('banner impression', gtmOptions);
    }
  }, [isViewed, gtmOptions]);

  if (
    !process.browser ||
    ownersGuideBannerClosed ||
    !didSaleCompleteInHalfYear(activeAccount?.latestSaleCompletionDate)
  ) {
    return null;
  }

  return (
    <div className={styles.root} ref={ref}>
      <img
        width={48}
        height={62}
        src="/assets/welcome-guides.svg"
        alt="guides"
      />

      <div className={styles.messageContainer}>
        <p className="owners-body">
          <b>{title}</b>
        </p>

        <p className="owners-body">
          We are very excited to welcome you to your new holiday home. To get to
          grips with everything you need to know, we have put together a number
          of guides for you to read to help you familarise yourself with
          ownership at Haven.
        </p>
      </div>

      <Button
        variant="outlinedWhite"
        className={styles.goToButton}
        onClick={() => {
          handleGTMBannerImpression('banner click', gtmOptions);
          router.push('/welcome-guides');
        }}
      >
        Go to guides
      </Button>

      <Button
        variant="text"
        className={styles.closeButton}
        onClick={() => {
          setOwnersGuideBannerClosed(true);

          // Persist user decision to local storage.
          localStorage.setItem(ownersGuideBannerReadKey, 'true');
          handleGTMBannerImpression('banner close', gtmOptions);
        }}
      >
        <img
          src="/assets/close_small_white.svg"
          width={16}
          height={16}
          alt="close"
        />
      </Button>
    </div>
  );
};

export default OwnersGuideBanner;
