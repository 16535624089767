import React, { FunctionComponent } from 'react';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { Content } from '@havenengineering/module-shared-owners-ui/dist/components/Content';
import { Footer } from '@havenengineering/module-shared-owners-ui/dist/components/Footer';
import { Header } from '@havenengineering/module-shared-owners-ui/dist/components/Header';
import { useAuthContext } from '@havenengineering/module-shared-owners-ui/dist/contexts/auth';

import { fetchWrapper, withAuthenticationUrl } from '../helpers/fetch';

const {
  publicRuntimeConfig: { PUBLIC_LOGIN_URL, PUBLIC_ENV },
} = getConfig();

export const MinimalLayout: FunctionComponent = ({ children }) => {
  const { loggedInUser } = useAuthContext();
  const router = useRouter();

  const logoutHandler = async () => {
    try {
      await fetchWrapper(withAuthenticationUrl('/logout'), {
        method: 'POST',
        body: '{}',
        credentials: 'include',
      });
      window.location.assign(window.location.origin);
      return;
    } catch (error: any) {
      window.alert('Failed to logout, please try again');
    }
  };

  const loginHandler = () => {
    window.location.assign(
      `${PUBLIC_LOGIN_URL}?redirect=${window.location.href}`
    );
  };

  const handleLogoClick = () => router.push('/');

  return (
    <>
      <Header
        userLoggedIn={!!loggedInUser}
        handleLogin={loginHandler}
        handleLogout={logoutHandler}
        handleLogoClick={handleLogoClick}
        environment={PUBLIC_ENV}
      />
      <Content isScrollable>{children}</Content>
      <Footer />
    </>
  );
};
