import React, { FunctionComponent } from 'react';
import getConfig from 'next/config';
import { Button } from '@havenengineering/module-shared-owners-ui/dist/components/Button';

import styles from './NoActivities.module.scss';

const {
  publicRuntimeConfig: { PUBLIC_ACTIVITIES_APP_URL },
} = getConfig();

export const NoActivities: FunctionComponent = () => (
  <div className={styles.noUpcomingContainer}>
    <span className="owners-body">No upcoming bookings</span>
    <a href={`${PUBLIC_ACTIVITIES_APP_URL}/activity-list`}>
      <Button className={styles.bookButton} variant="outlined">
        Book now
      </Button>
    </a>
  </div>
);
