import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import Button from '@havenengineering/module-shared-owners-ui/dist/components/Button';
import ConfirmationOverlay from '@havenengineering/module-shared-owners-ui/dist/components/ConfirmationOverlay';
import { EventData } from '@havenengineering/module-shared-owners-ui/dist/components/EventCalendar';
import { useAuthContext } from '@havenengineering/module-shared-owners-ui/dist/contexts/auth';
import clsx from 'clsx';

import useOnboardingTooltips from '../../hooks/useOnboardingTooltips';
import { useScreenWidth } from '../../hooks/useScreenWidth';
import {
  ArrivalDataEnum,
  BookingTab,
  BreakEventStatus,
  EventDateSelection,
  Panels,
} from '../../pages/bookings';
import { TooltipType } from '../../types/onboardingTooltip';
import {
  deleteArrivalBookingOrHavenLet,
  Let2OwnSummary,
} from '../helpers/bookings';
import { TooltipFactory } from '../TooltipFactory/TooltipFactory';
import styles from './InfoPanel.module.scss';
import { InfoPanelView } from './InfoPanelView';
import { PanelHeader } from './PanelHeader';
export interface InfoSelection {
  dates: EventDateSelection;
  events?: EventData[];
}

interface InfoPanelProps {
  selection: InfoSelection;
  arrivals?: EventData<ArrivalBooking>[];
  handleCloseActionBar: () => void;
  handleArrivalEdit: (editedBooking: ArrivalBooking) => void;
  handleArrivalBooking: (shortCode: string) => void;
  letWithHavenBookingToEdit?: BreakDataResponse;
  handleLetWithHavenEdit: (selectedBreak: { startDate: string }) => void;
  handleLetWithHaven: (outOfSeason: boolean) => void;
  initialPanel?: Panels;
  activeLettingAd?: Advert;
  fromAddEvent: boolean;
  handleDateSelectionChange: (newSelection: EventDateSelection) => void;
  letWithHavenEnabled: boolean;
  isParkClosed: boolean;
  allowedToLet: boolean;
  parkAllowsLetting: boolean;
  hasSubmittedApplication: boolean;
  firstEventStatus: BreakEventStatus;
  tabView: BookingTab;
  mobileView: boolean;
  refetchEventData: (closeActionBar?: boolean) => void;
  setError?: (msg: string) => void;
  setInfo?: (msg: string) => void;
  activeSeason: number;
  ownersCards: OwnersCardResponse | null;
  allPeakDatesData: PeakDatesData[];
  let2OwnSummary?: Let2OwnSummary;
  setCalendarLoading: (isLoading: boolean) => void;
}

export const InfoPanel: FunctionComponent<InfoPanelProps> = (props) => {
  const {
    initialPanel,
    selection,
    handleCloseActionBar,

    fromAddEvent,
    mobileView,
    refetchEventData,
    setError,
    setInfo,
  } = props;

  const { activeAccount } = useAuthContext();
  const bookTooltipRef = useRef(null);
  const detailsTooltipRef = useRef(null);
  const [firstRender, setFirstRender] = useState(true);
  const [mobileInnerInitialPanel, setMobileInnerInitialPanel] = useState<
    Panels | undefined
  >(initialPanel);
  const [singleEventSelection, setSingleEventSelection] = useState(false);
  const [editMobileViewSelected, setEditMobileViewSelected] = useState(false);
  const [singleEventModalOpen, setSingleEventModalOpen] = useState(false);
  const xsView = useScreenWidth(400);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

  const desktopInitialPanel =
    initialPanel === Panels.INDEX
      ? selection.events?.length
        ? Panels.INDEX
        : Panels.BOOKING
      : initialPanel;

  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const { tooltip } = useOnboardingTooltips(TooltipType.CALENDAR_EVENT_BOOK);
  useEffect(() => {
    setShowTooltip(!!tooltip);
  }, [tooltip]);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
    }
  }, [firstRender]);

  useEffect(() => {
    if (initialPanel === Panels.ARRIVAL_EDIT && mobileView) {
      handlePanelSelect(Panels.ARRIVAL_EDIT);
    }
    if (initialPanel === Panels.LET_WITH_HAVEN_EDIT && mobileView) {
      handlePanelSelect(Panels.LET_WITH_HAVEN_EDIT);
    }
  }, [initialPanel, mobileView]);

  useEffect(() => {
    if (fromAddEvent && mobileView) {
      handlePanelSelect(Panels.BOOKING);
      setEditMobileViewSelected(true);
    }
  }, [fromAddEvent, mobileView]);

  useEffect(() => {
    setSingleEventSelection(mobileView && selection?.events?.length === 1);
  }, [mobileView, selection?.events]);

  const handlePanelSelect = (panel: Panels) =>
    setMobileInnerInitialPanel(panel);

  const handleArrivalEditPanelSelect = () => {
    setEditMobileViewSelected(true);
    handlePanelSelect(Panels.ARRIVAL_EDIT);
  };

  const handleDeleteSelect = () => {
    setCancelDialogOpen(true);
  };

  const handleProceedOnDelete = async () => {
    if (!selection?.events?.[0]) {
      return;
    }
    const eventSelection = selection.events[0];
    const { success, error, info } = await deleteArrivalBookingOrHavenLet(
      activeAccount?.accountID as number,
      eventSelection,
      props.allPeakDatesData,
      props.let2OwnSummary
    );

    if (success) {
      refetchEventData();
    } else {
      error && setError && setError(error);
      info && setInfo && setInfo(info);
    }
  };

  const handleAddBookClick = () => {
    handlePanelSelect(Panels.BOOKING);
    setSingleEventModalOpen(true);
  };

  return (
    <>
      <ConfirmationOverlay
        isOpen={cancelDialogOpen}
        title="You’re about to delete a booking!"
        content={
          <>
            <p>This action can’t be undone.</p>
            <p>
              <b>Are you sure?</b>
            </p>
          </>
        }
        handleOk={() => {
          setCancelDialogOpen(false);
          handleProceedOnDelete();
        }}
        handleCancel={() => setCancelDialogOpen(false)}
        okLabel="Yes, proceed"
        cancelLabel="No, go back"
      />
      {!firstRender && !mobileView && (
        <InfoPanelView
          {...{
            ...props,
            initialPanel: desktopInitialPanel,
            initialBookingToEdit: selection?.events?.[0]?.data,
          }}
        />
      )}
      {mobileView && (
        <>
          {mobileInnerInitialPanel && selection && !singleEventModalOpen && (
            <div className={styles.mobileToolbar}>
              <PanelHeader
                selection={selection}
                handleCloseActionBar={handleCloseActionBar}
              />
              <div className={styles.summaryActions}>
                <Button onClick={handleAddBookClick}>
                  <img
                    className={styles.addBookingIcon}
                    src="/assets/plus_white.svg"
                    alt=""
                    role="presentation"
                  />
                  Add booking
                </Button>
                <Button
                  onClick={() => setSingleEventModalOpen(true)}
                  variant="outlined"
                  disabled={!(selection?.events || [])?.length}
                >
                  See details
                </Button>
              </div>
            </div>
          )}
          {mobileInnerInitialPanel && selection && singleEventModalOpen && (
            <div className={styles.mobileInfoWrapper}>
              <InfoPanelView
                {...{
                  ...props,
                  initialPanel: mobileInnerInitialPanel,
                  initialBookingToEdit: selection?.events?.[0]?.data,
                }}
              />
            </div>
          )}
          {mobileInnerInitialPanel &&
            singleEventSelection &&
            editMobileViewSelected && (
              <div className={styles.mobileInfoWrapper}>
                <InfoPanelView
                  {...{
                    ...props,
                    initialPanel: mobileInnerInitialPanel,
                    initialBookingToEdit: selection?.events?.[0]?.data,
                  }}
                />
              </div>
            )}

          {!mobileInnerInitialPanel && (
            <div className={styles.mobileInitalPanelSelector}>
              <PanelHeader
                className={styles.mobileSelectorHeader}
                selection={selection}
                handleCloseActionBar={handleCloseActionBar}
              />

              <div className={styles.actions}>
                <div ref={bookTooltipRef}>
                  <Button onClick={() => handlePanelSelect(Panels.BOOKING)}>
                    <img
                      className={clsx(styles.buttonIcon, styles.add)}
                      src="/assets/plus_white.svg"
                      alt=""
                      role="presentation"
                    />
                    Add booking
                  </Button>
                </div>

                {singleEventSelection &&
                  selection?.events?.[0]?.code ===
                    ArrivalDataEnum.ARRIVAL_BOOKING && (
                    <div onClick={handleArrivalEditPanelSelect}>
                      {xsView ? (
                        <img
                          className={styles.onlyButtonIcon}
                          src="/assets/icon-edit.svg"
                          alt=""
                          role="presentation"
                        />
                      ) : (
                        <Button variant="outlined">
                          <img
                            className={styles.buttonIcon}
                            src="/assets/icon-edit.svg"
                            alt=""
                            role="presentation"
                          />
                          Edit
                        </Button>
                      )}
                    </div>
                  )}

                {singleEventSelection && (
                  <div onClick={handleDeleteSelect}>
                    {xsView ? (
                      <img
                        className={styles.onlyButtonIcon}
                        src="/assets/icon-delete.svg"
                        alt=""
                        role="presentation"
                      />
                    ) : (
                      <Button variant="outlined">
                        <img
                          className={styles.buttonIcon}
                          src="/assets/icon-delete.svg"
                          alt=""
                          role="presentation"
                        />
                        Delete
                      </Button>
                    )}
                  </div>
                )}

                {!singleEventSelection && selection.events?.length !== 0 && (
                  <div ref={detailsTooltipRef}>
                    <Button
                      variant="outlined"
                      onClick={() => handlePanelSelect(Panels.INDEX)}
                    >
                      All bookings
                    </Button>
                  </div>
                )}

                <TooltipFactory
                  tooltipType={TooltipType.CALENDAR_EVENT_BOOK}
                  handleDismiss={() => setShowTooltip(false)}
                  parentRef={bookTooltipRef}
                />

                {!showTooltip &&
                  !singleEventSelection &&
                  selection.events?.length !== 0 && (
                    <TooltipFactory
                      tooltipType={TooltipType.CALENDAR_EVENT_DETAIL}
                      parentRef={detailsTooltipRef}
                    />
                  )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
